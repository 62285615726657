export const FETCH_REFERRAL_NAME = "FETCH_REFERRAL_NAME"
export const SET_REFERRAL_NAME = "SET_REFERRAL_NAME"
export const SET_MESSAGE = "SET_MESSAGE"
export const SET_HEADER_DROPDOWN = "SET_HEADER_DROPDOWN"
export const SET_MOBILE_SUBMENU = "SET_MOBILE_SUBMENU"
export const SET_DESKTOP_SUBMENU = "SET_DESKTOP_SUBMENU"
export const FETCH_CART_LIST = "FETCH_CART_LIST"
export const FETCH_LOGIN_RESULT = "FETCH_LOGIN_RESULT"
export const FETCH_WISH_LIST = "FETCH_WISH_LIST"
export const SET_WISH_LIST = "SET_WISH_LIST"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST"
export const ACTIVATE_MENU = "ACTIVATE_MENU"
export const DEACTIVATE_MENU = "DEACTIVATE_MENU"
export const ACTIVATE_ACCOUNT_MENU = "ACTIVATE_ACCOUNT_MENU"
export const DEACTIVATE_ACCOUNT_MENU = "DEACTIVATE_ACCOUNT_MENU"
export const ACTIVATE_SUBMENU = "ACTIVATE_SUBMENU"
export const DEACTIVATE_SUBMENU = "DEACTIVATE_SUBMENU"
export const SHOW_SUBCATEGORY = "SHOW_SUBCATEGORY"
export const SHOW_INFO_INDEX = "SHOW_INFO_INDEX"

export const SET_USER_ADDRESSES = "SET_USER_ADDRESSES"
export const UPDATE_USER_ADDRESS = "UPDATE_USER_ADDRESS"
export const REMOVE_USER_ADDRESS = "REMOVE_USER_ADDRESS"
export const ADD_USER_ADDRESS = "ADD_USER_ADDRESS"

export const ACTIVATE_HELP_BAR = "ACTIVATE_HELP_BAR"
export const DEACTIVATE_HELP_BAR = "DEACTIVATE_HELP_BAR"

export const UPDATE_SEARCH_VALUE = "UPDATE_SEARCH_VALUE"
export const FETCH_SEARCH_SUGGESTIONS = "FETCH_SEARCH_SUGGESTIONS"
export const MAY_UPDATE_SEARCH_SUGGESTIONS = "MAY_UPDATE_SEARCH_SUGGESTIONS"
export const CLEAR_SEARCH_SUGGESTIONS = "CLEAR_SEARCH_SUGGESTIONS"
export const CLEAR_SEARCH_USER_INPUT = "CLEAR_SEARCH_USER_INPUT"
export const SAVE_USER_SEARCH_HISTORY = "SAVE_USER_SEARCH_HISTORY"
export const DISPLAY_USER_SEARCH_HISTORY = "DISPLAY_USER_SEARCH_HISTORY"
export const SET_EXPLICIT_LOGIN = "SET_EXPLICIT_LOGIN"
export const DO_LOGIN = "DO_LOGIN"
export const DO_LOGOUT = "DO_LOGOUT"
export const FORGOT_PASSWORD = "FORGOT_PASSWORD"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const SET_LOGIN_NOTIFICATION = "SET_LOGIN_NOTIFICATION"
export const CLEAR_LOGIN_CALLBACK = "CLEAR_LOGIN_CALLBACK"
export const SEND_USER_INFO = "SEND_USER_INFO"
export const SET_USER_DATA = "SET_USER_DATA"
export const SET_FACEBOOK_LOGIN_STATUS = "SET_FACEBOOK_LOGIN_STATUS"
export const SET_COOKIE_LOGIN_STATUS = "SET_COOKIE_LOGIN_STATUS"
export const FACEBOOK_CONNECT = "FACEBOOK_CONNECT"
export const FACEBOOK_LOGIN = "FACEBOOK_LOGIN"
export const SET_FACEBOOK_LOGIN_PROCESS_STATE =
  "SET_FACEBOOK_LOGIN_PROCESS_STATE"

export const SUBMIT_REGISTRATION = "SUBMIT_REGISTRATION"
export const SET_FORM_PAUSE = "SET_FORM_PAUSE"
export const SET_POINTS_INFO = "SET_POINTS_INFO"
export const UPDATE_POINTS_ACCOUNT = "UPDATE_POINTS_ACCOUNT"

export const FETCH_TRACKING_INFO = "FETCH_TRACKING_INFO"
export const UPDATE_TRACKING_INFO = "UPDATE_TRACKING_INFO"

export const UNSELECT_FROM_WISHLIST = "UNSELECT_FROM_WISHLIST"

export const SHOW_LOCALE_HM = "SHOW_LOCALE_HM"
export const HIDE_LOCALE_HM = "HIDE_LOCALE_HM"
export const SET_TARGET_COUNTRY = "SET_TARGET_COUNTRY"
export const SET_TARGET_LOCALE = "SET_TARGET_LOCALE"

export const TOGGLE_COUNTRY_MODAL = "TOGGLE_COUNTRY_MODAL"
export const SET_EXPERIMENT = "SET_EXPERIMENT"

export const ADD_ALERT = "ADD_ALERT"
export const REMOVE_ALERT = "REMOVE_ALERT"

export const SWITCH_COUNTRY = "SWITCH_COUNTRY"

// scrollMap
export const SET_SCROLL_POS = "SET_SCROLL_POS"
export const TOGGLE_SCROLL_TRACK = "TOGGLE_SCROLL_TRACK"

export function fetchTrackingInfo() {
  return { type: FETCH_TRACKING_INFO }
}

export function updateTrackingInfo(tracking_result) {
  return { tracking_result, type: UPDATE_TRACKING_INFO }
}

/**
 * This fetches the First Name/Last Initial of the user that referred a customer.
 * Triggers the fetch-referral-name saga which calls Users API
 */
export function fetchReferralName(ref) {
  return { ref, type: FETCH_REFERRAL_NAME }
}

/**
 * This sets the First Name/Last Initial of the referring user into the store
 * Sets `state.referral_name`
 */
export function setReferralName(referralName) {
  return { referralName, type: SET_REFERRAL_NAME }
}

/**
 * Adds address to user addresses
 * @param  {Object} address
 * @param  {Object} actions
 */
export function addUserAddress(address, actions) {
  return { actions, address, type: ADD_USER_ADDRESS }
}

/**
 * Disables address in user addresses
 *  It is disabled because it cannot be deleted.
 *  The user ID is dissassociated instead
 * @param  {Object} address
 * @param  {Object} actions
 */
export function removeUserAddress(address, actions) {
  return { actions, address, type: REMOVE_USER_ADDRESS }
}

/**
 * Sets the user address data returned from AJAX calls
 * @param  {Object} data
 */
export function setUserAddresses(data) {
  return { data, type: SET_USER_ADDRESSES }
}

/**
 * Updates address in user addresses
 * @param  {Object} address
 * @param  {Object} actions
 */
export function updateUserAddress(address, actions) {
  return { actions, address, type: UPDATE_USER_ADDRESS }
}

export function setPointsInfo(info) {
  return { info, type: SET_POINTS_INFO }
}
export function updatePointsAccount(account) {
  return { account, type: UPDATE_POINTS_ACCOUNT }
}

export function setMobileSubmenu(submenu) {
  return { submenu, type: SET_MOBILE_SUBMENU }
}
export function setDesktopSubmenu(submenu) {
  return { submenu, type: SET_DESKTOP_SUBMENU }
}

export function activateHelpBar() {
  return { type: ACTIVATE_HELP_BAR }
}
export function deactivateHelpBar() {
  return { type: DEACTIVATE_HELP_BAR }
}

/**
 * This sets a message under the specified key with a value (the message)
 * Sets state.messages[key] to object with value
 * @param {string} key The key of the message object to set
 * @param {string} messageType The type of the message to set (i.e. error, warning, success)
 * @param {string} value The message to set
 */
export function setMessage(key, messageType, value) {
  return { key, messageType, type: SET_MESSAGE, value }
}

export function activateMenu() {
  return { type: ACTIVATE_MENU }
}

export function deactivateMenu() {
  return { type: DEACTIVATE_MENU }
}

export function activateAccountMenu() {
  return { type: ACTIVATE_ACCOUNT_MENU }
}

export function deactivateAccountMenu() {
  return { type: DEACTIVATE_ACCOUNT_MENU }
}

export function activateSubmenu() {
  return { type: ACTIVATE_SUBMENU }
}

export function deactivateSubmenu() {
  return { type: DEACTIVATE_SUBMENU }
}

export function updateSubcategoryIndex(index) {
  return { index, type: SHOW_SUBCATEGORY }
}

export function updateInfoIndex(index) {
  return { index, type: SHOW_INFO_INDEX }
}

export function setHeaderDropdown(dropdownName) {
  return { dropdownName, type: SET_HEADER_DROPDOWN }
}
/**
 * This action triggers a saga that calls load_react_cart to fetch latest cart data.
 * @param  {Boolean} forces the saga to fetch the cart list, used when a user logs out and back in.
 */
export function fetchCartList(forceFetch = false) {
  return { forceFetch, type: FETCH_CART_LIST }
}
/**
 * This action triggers a saga that calls fetch_wish_list to fetch latest wishlist data.
 * @param  {Boolean} forces the saga to fetch the wish list, used when a user logs out and back in.
 */
export function fetchWishList(forceFetch = false) {
  return { forceFetch, type: FETCH_WISH_LIST }
}
export function fetchLoginResult(credential, action = {}) {
  return { action, credential, type: FETCH_LOGIN_RESULT }
}
export function setWishList(wishlist) {
  return { type: SET_WISH_LIST, wishlist }
}
export function removeFromCart(id, isAddon) {
  return { id, isAddon, type: REMOVE_FROM_CART }
}
export function removeFromWishlist(id) {
  return { id, type: REMOVE_FROM_WISHLIST }
}

export function updateSearchValue(value) {
  return { type: UPDATE_SEARCH_VALUE, value }
}
export function fetchSearchSuggestions(query) {
  return { query, type: FETCH_SEARCH_SUGGESTIONS }
}
export function mayUpdateSearchSuggestions(suggestions, value) {
  return { suggestions, type: MAY_UPDATE_SEARCH_SUGGESTIONS, value }
}
export function clearSearchSuggestions() {
  return { type: CLEAR_SEARCH_SUGGESTIONS }
}
export function clearSearchUserInput() {
  return { type: CLEAR_SEARCH_USER_INPUT }
}
export function saveUserSearchHistory(query) {
  return { query, type: SAVE_USER_SEARCH_HISTORY }
}

export function displaySavedUserSearchHistory() {
  return { type: DISPLAY_USER_SEARCH_HISTORY }
}
/**
 * This ensures that the account pop-in appears after successful login
 * Sets `state.explicitLogin` to `true`
 * @example
 * dispatch(setExplicitLogin())
 */
export function setExplicitLogin() {
  return { type: SET_EXPLICIT_LOGIN }
}

/**
 * This performs a login based on the Jewlr4 API response by setting the relevant data into `state.user`
 * and calling the doLogin redux saga
 * @param  {object} The user object returned by jewlrorders
 * @param  {string} Simple string argument which clarifies the type of connection (i.e. facebook, jewlrorders)
 * @param  {Boolean} Remember flag, simply extends the life of the cookie
 */
export function doLogin(loginObj, loginType, loginRemember = false) {
  return { loginObj, loginRemember, loginType, type: DO_LOGIN }
}

export function doLogout(forceInternal = false, callback) {
  return { callback, forceInternal, type: DO_LOGOUT }
}

/**
 * This action will trigger the forgotPassword saga which will send a password reset link to the user.
 * @param  {string} email The email associated with the users account
 */
export function doForgotPassword(email, actions) {
  return { actions, email, type: FORGOT_PASSWORD }
}

/**
 * This action will trigger the resetPassword saga which will send a reset password request.
 * @param  {string} values  Object consists of password and password_confirm
 */
export function doResetPassword(values, actions) {
  return { actions, type: RESET_PASSWORD, values }
}

/**
 * This clears any callback action that was set in `state.user.callback`
 */
export function clearLoginCallback() {
  return { type: CLEAR_LOGIN_CALLBACK }
}

/**
 * This sends the user information filled out in the provide-user-info.jsx form to Jewlr4 (and Jewlrorders)
 * Data returned is passed back into setUserData in the send-user-info saga`
 * @param  {object} values        The form values containin the user info
 * @param  {object} actions       The callbacks when the form submission has been completed
 */
export function sendUserInfo(values, actions) {
  return {
    actions,
    type: SEND_USER_INFO,
    values,
  }
}

/**
 * This sends an message to login/register components based on the success/failure of login
 * @param {string}  string indicating the error of the login/register
 */
export function setLoginNotification(data) {
  return { data, type: SET_LOGIN_NOTIFICATION }
}

export function setUserData(userObj) {
  return { type: SET_USER_DATA, userObj }
}

export function setFacebookLoginStatus(response) {
  return { response, type: SET_FACEBOOK_LOGIN_STATUS }
}

export function setCookieLoginStatus(action) {
  return { action, type: SET_COOKIE_LOGIN_STATUS }
}

export function facebookConnect(data) {
  return { data, type: FACEBOOK_CONNECT }
}

export function setFacebookLoginState(status) {
  return {
    status,
    type: SET_FACEBOOK_LOGIN_PROCESS_STATE,
  }
}

/**
 * Triggers the Facebook Status function so we can pass the Facebook User's auth token to our own
 * methods and set them up with a Jewlrorders login
 * @param {object} action callback object to run once the full login has been completed. Is set on `state.user.callback`
 */
export function facebookLogin(callback) {
  return { callback, type: FACEBOOK_LOGIN }
}

export function submitRegistration(values, actions = {}) {
  return { actions, type: SUBMIT_REGISTRATION, values }
}

export function setFormPause(bool) {
  return { bool, type: SET_FORM_PAUSE }
}

export function unselectFromWishList(style_code) {
  return {
    style_code,
    type: UNSELECT_FROM_WISHLIST,
  }
}

export function showLocaleHM() {
  return {
    type: SHOW_LOCALE_HM,
  }
}

export function hideLocaleHM() {
  return {
    type: HIDE_LOCALE_HM,
  }
}

export function setTargetCountry(data) {
  return {
    data,
    type: SET_TARGET_COUNTRY,
  }
}

export function setTargetLocale(data) {
  return {
    data,
    type: SET_TARGET_LOCALE,
  }
}

export function toggleCountryModal(toggle) {
  return {
    toggle,
    type: TOGGLE_COUNTRY_MODAL,
  }
}

export function setExperiment(experiment) {
  return {
    experiment,
    type: SET_EXPERIMENT,
  }
}

export function addAlert(alert) {
  return { alert, type: ADD_ALERT }
}

export function removeAlert(id) {
  return { id, type: REMOVE_ALERT }
}

export function switchCountry(country) {
  return { country, type: SWITCH_COUNTRY }
}

export function setScrollPos(urlPath, scrollPos) {
  return { scrollPos, type: SET_SCROLL_POS, urlPath }
}

export function toggleScrollTrack(active) {
  return { active, type: TOGGLE_SCROLL_TRACK }
}
